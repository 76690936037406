<template>
	<div :class="{ 'px-3': isSmAndDown }">
		<v-row class="mt-1 mb-2">
			<v-col class="px-0">
				<h3>결제완료</h3>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" md="12" sm="12" class="pt-0 px-0">
				<v-card class="py-4 px-7">
					<v-list>
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title>
									<h4>결제 정보</h4>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-divider class="my-4" />
						<v-list-item two-line>
							<v-list-item-content>
								<v-list-item-subtitle class="text-xs">
									가격
								</v-list-item-subtitle>
								<v-list-item-title v-if="event">
									<div v-if="event.amount > 0">
										<span class="amount primary--text">
											{{ event.amount | commaFormat }} 원
										</span>
									</div>
									<div v-else>
										<span class="amount primary--text">무료</span>
									</div>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-card>
			</v-col>
			<v-col cols="12" md="12" sm="12" class="px-0 pt-0">
				<v-card class="py-4 px-7">
					<v-list>
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title>
									<h4>신청 정보</h4>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-divider class="my-4" />
						<v-list-item two-line>
							<v-list-item-content>
								<v-list-item-subtitle class="text-xs">
									타이틀
								</v-list-item-subtitle>
								<v-list-item-title v-if="event && event.title">
									{{ event.title }}
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item two-line>
							<v-list-item-content>
								<v-list-item-subtitle>일정</v-list-item-subtitle>
								<v-list-item-title v-if="event && event.eventAt">
									{{ event.eventAt | dateSiFormat }}
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item two-line>
							<v-list-item-content>
								<v-list-item-subtitle class="text-xs">
									신청 마감일
								</v-list-item-subtitle>
								<v-list-item-title v-if="event">
									<span v-if="event.endRegistrationDate">
										{{ event.endRegistrationDate | _dateFormat }}
									</span>
									<span v-else>-</span>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item two-line>
							<v-list-item-content>
								<v-list-item-subtitle class="text-xs">
									모집 인원
								</v-list-item-subtitle>
								<v-list-item-title v-if="event && event.maxParticipants">
									{{ event.maxParticipants }}명
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-divider class="my-3" />
						<v-list-item two-line>
							<v-list-item-content>
								<v-list-item-subtitle class="text-xs">
									이름
								</v-list-item-subtitle>
								<v-list-item-title>{{ profile.name }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item two-line>
							<v-list-item-content>
								<v-list-item-subtitle class="text-xs">
									이메일
								</v-list-item-subtitle>
								<v-list-item-title>{{ profile.email }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item two-line>
							<v-list-item-content>
								<v-list-item-subtitle class="text-xs">
									휴대폰번호
								</v-list-item-subtitle>
								<v-list-item-title>{{ profile.phoneNumber }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-card>
				<div class="d-flex justify-center my-5">
					<v-btn
						color="primary"
						class="w-full"
						:to="{ name: 'mypage-license' }"
					>
						신청보드로 가기
					</v-btn>
				</div>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'

import store from '@/store'
import { applicationStatus } from '@/helpers'
import { warningSwal } from '@/plugins/swalMixin'
import { useRouter, getVuetify } from '@core/utils'

import EventService from '@/services/EventService'

export default {
	setup() {
		const $vuetify = getVuetify()
		const profile = computed(() => store.getters['user/getMeDetail'])
		const { route, router } = useRouter()
		const event = ref()
		const eventParticipations = ref()
		const eventId = route.value.params.eventId

		const getEvent = async () => {
			event.value = await EventService.getEvent(eventId)
		}
		getEvent(eventId)

		const getEventParticipations = async () => {
			const [data] = await EventService.getEventParticipations(eventId)
			const allowStatuses = [
				applicationStatus.APPLIED.value,
				applicationStatus.PAID.value,
			]

			if (!allowStatuses.includes(data.status)) {
				const confirm = await warningSwal('신청 처리가 되지 않았습니다.')
				if (confirm.isConfirmed) {
					router.go(-1)
				}
			}

			eventParticipations.value = data
		}

		const isSmAndDown = computed(() => {
			return $vuetify.breakpoint.smAndDown
		})

		getEventParticipations()

		window.scrollTo(0, 0)

		return {
			event,
			profile,
			isSmAndDown,
		}
	},
}
</script>
<style lang="scss" scoped>
.v-list-item {
	padding: 0;

	&__subtitle {
		color: #aaaaaf !important;
	}

	span.amount {
		font-size: 24px;
		font-weight: 900;
		letter-spacing: -0.5px;
	}
}

.button {
	width: 100%;
	max-width: 100%;
	min-height: 48px;
}

.full-width {
	width: 100%;
	max-width: 100%;
}
</style>
